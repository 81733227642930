<template>
  <div>
    <ul class="stars">
      <li>
        <star :fill="totalEvaluation" />
      </li>
      <li>
        <star :fill="totalEvaluation - 1" />
      </li>
      <li>
        <star :fill="totalEvaluation - 2" />
      </li>
      <li>
        <star :fill="totalEvaluation - 3" />
      </li>
      <li>
        <star :fill="totalEvaluation - 4" />
      </li>
    </ul>
  </div>
</template>

<script>
import Star from '@/components/project_owner/Star';

export default {
  name: 'Stars',
  components: { Star },
  props: {
    totalEvaluation: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
ul.stars {
  display: flex;
  gap: 0 4px;
}
</style>
