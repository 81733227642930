<template>
  <div v-if="fill <= 0">
    <img
      alt=""
      height="18"
      src="@/assets/project/owner/rate/star_empty.svg"
      width="18"
    />
  </div>
  <div v-if="0 < fill && fill < 1">
    <img
      alt=""
      height="18"
      src="@/assets/project/owner/rate/star_half.svg"
      width="18"
    />
  </div>
  <div v-if="fill >= 1">
    <img
      alt=""
      height="18"
      src="@/assets/project/owner/rate/star_full.svg"
      width="18"
    />
  </div>
</template>

<script>
/**
 * 星を表示するコンポーネント
 * fillが0なら空、0より大きく1より小さければ半分、1以上なら全塗りの星を表示する
 * @param {Number} fill
 */
export default {
  name: 'Star',
  props: {
    fill: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
img {
  display: block;
  height: 18px;
  width: 18px;
}
</style>
