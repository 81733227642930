import { createApp } from 'vue';
import ProjectOwnerRate from '@/components/project_owner/Rate';

const el = document.getElementById('vue-project-owner-rate');

if (el !== null) {
  const { userId } = el.dataset;

  const app = createApp(ProjectOwnerRate, { userId: Number(userId) });
  app.mount('#vue-project-owner-rate');
}
